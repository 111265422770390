<script setup lang="ts">
// @ts-nocheck
import { useIntersectionObserver } from '@vueuse/core/index'
import LifeVideo from '~/components/pages/home/life-video.vue'

const lifeStories = [
  {
    id: 'life-1',
    school: 'LAW',
    title: 'Student Societies',
    mp4: 'https://api.newvision.ge/media/rt4pkcwu/lifeatnvu1.mp4',
    webm: 'https://api.newvision.ge/media/uazpfuq3/lifeatnvu1.webm',
    url: '/page/4-student-alumni/student-societies/',
  },
  {
    id: 'life-2',
    school: 'MEDICINE',
    title: 'Healthy Lifestyle',
    mp4: 'https://api.newvision.ge/media/ilgpjqnq/lifeatnvu2-new.mp4',
    webm: 'https://api.newvision.ge/media/q45lhg41/lifeatnvu2-new.webm',
    url: '/page/4-student-alumni/healthy-lifestyle/',
  },
  {
    id: 'life-3',
    school: 'BUSINESS AND IT',
    title: 'Alumni',
    mp4: 'https://api.newvision.ge/media/q1ahl4fj/lifeatnvu3.mp4',
    webm: 'https://api.newvision.ge/media/djzffvtp/lifeatnvu3.webm',
    url: '/page/4-student-alumni/alumni/',
  },
]
const video = ref(null)
const videoIsVisible = ref(false)

const { stop } = useIntersectionObserver(
  video,
  ([{ isIntersecting }], observerElement) => {
    videoIsVisible.value = isIntersecting
  }
)
</script>

<template>
  <div class="font-notoSansGeorgian overflow-y-scroll h-full no-scrollbar">
    <span class="text-black-950 font-bold text-2xl">
      {{ $t('Life at New Vision') }}
    </span>
    <!--    <span class="text-black-700 font-normal text-base block mt-6 max-w-[450px]">-->
    <!--      Exploring tales of individuals, research breakthroughs, and innovative-->
    <!--      strides within the agricultural realm.-->
    <!--    </span>-->
    <div ref="video" class="h-full">
      <div
        class="flex gap-x-6 overflow-x-scroll mt-2 h-full no-scrollbar snap-x"
      >
        <div
          v-for="life in lifeStories"
          :key="life.id"
          class="lg:w-1/3 w-[90%] shrink-0 h-[90%] snap-start"
        >
          <LifeVideo :life="life"></LifeVideo>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss"></style>
